import React from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import SliderItem from "./item";

import Desk from "./../../assets/home-desk.png";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Slider() {
  return (
    <div className="container position-relative z-index">
      <div className="row">
        <div className="col-lg-12">
          <AutoPlaySwipeableViews
            style={{ overflowX: "hidden", overflowY: "inherit" }}
            slideStyle={{ overflowX: "hidden", overflowY: "inherit" }}
          >
            <SliderItem
              key="#SW-0"
              title="INTELIGÊNCIA ARTIFICIAL"
              text={[
                "Otimização do Planejamento de Produção",
                "Otimização do Transporte",
                "Monitoramento do Processo Produtivo",
              ]}
              image={Desk}
            />
            {/* <SliderItem
              title={"Garanta o sucesso da sua empresa"}
              text={"Entre em contato"}
              button={{ text: "a", url: "b" }}
              image={Desk}
            /> */}
          </AutoPlaySwipeableViews>
        </div>
      </div>
    </div>
  );
}

export default Slider;
