import React from "react";

function GoogleMap({ url }) {
  return (
    <iframe
      title="maps"
      src={url}
      frameBorder={0}
      style={{ border: 0, marginTop: 20 }}
      width="100%"
      height={250}
    ></iframe>
  );
}

export default GoogleMap;
