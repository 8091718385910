import React from "react";

import Check from "mdi-react/CheckIcon";

function ProductsItem({ index, image, title, text, features }) {
  const showFeatures =
    features !== null && Array.isArray(features) && features.length > 0;
  return (
    <div className="row mt-5 vertical-content">
      {index % 2 === 0 && (
        <div className="col-lg-6">
          <div className="mt-3 features_img">
            <img src={image} alt="" className="img-fluid mx-auto d-block" />
          </div>
        </div>
      )}

      <div className="col-lg-6">
        <div className="features_desc mt-3">
          <div className="features_heading">
            <h2>{title}</h2>
            {typeof text === "string" ? (
              <p className="text-muted mb-0 mt-4">{text}</p>
            ) : (
              <div className="text-muted mb-0 mt-4">{text}</div>
            )}
          </div>

          {showFeatures &&
            features.map((f, i) => (
              <div key={`#${i}`} className="features">
                <div className="features-icon features-left">
                  <Check />
                </div>
                <div className="features-desc">
                  <h3 className="features-title font-weight-bold mb-0">
                    {f.title}
                  </h3>
                  <p className="features-sub-title pt-2 text-muted">{f.text}</p>
                </div>
              </div>
            ))}
        </div>
      </div>

      {index % 2 === 1 && (
        <div className="col-lg-6">
          <div className="mt-3 features_img">
            <img src={image} alt="" className="img-fluid mx-auto d-block" />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductsItem;
