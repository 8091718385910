/**
 * Links das redes sociais que são localizada no fim da pagina
 * Se deixar null o icone não é exibido.
 * Nota: Não apague a propriedade, apenas sete `null`.
 */
export const Social = {
  twitter: null,
  linkedin: null,
  facebook: null,
  instagram: null,
};

/**
 * Metodos de contado
 * Tipos pré-definidos:
 *  tel - Icone de telefone
 *  cell - Icone de celular
 *  email - icone de e-mail
 */
export const Contact = [
  { type: "tel", text: "+55 (18) 3229-3283 - Ramal 39" },
  { type: "cell", text: "+55 (18) 99730-1237" },
  { type: "email", text: "contato@playsis.com.br" },
];

export const Address = [
  {
    text:
      "Rodovia Raposo Tavares, Km 572 – Bloco B1 – Térreo 1 Bairro Limoeiro – Presidente Prudente – SP",
    maps:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1509.844859632622!2d-51.44806661152025!3d-22.115988927449248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9493f3e1b94a5dc3%3A0x1fea2357845d6823!2sIncubadora%20Tecnol%C3%B3gica%20de%20Presidente%20Prudente!5e0!3m2!1spt-BR!2sbr!4v1572291404047!5m2!1spt-BR!2sbr",
  },
];
