import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-scroll";
import MenuIcon from "mdi-react/MenuIcon";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

// logos
import LogoDark from "./../../assets/logo-dark.png";
import LogoWhite from "./../../assets/logo-white.png";

function Menu() {
  const [stick, setStick] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    const isStick = currPos.y < -50;
    if (isStick !== stick) {
      setStick(isStick);
    }
  });

  const collapseMenu = () => {
    setShowMenu((prev) => !prev);
  };
  const hideMenu = () => {
    setShowMenu(false);
  };

  return (
    <nav
      className={`navbar navbar-expand-lg fixed-top custom-nav sticky ${
        stick ? "stickyadd" : ""
      }`}
    >
      <div className="container">
        <a className="logo navbar-brand" href="#home">
          <img src={LogoDark} alt="" className="img-fluid logo-dark" />
          <img src={LogoWhite} alt="" className="img-fluid logo-light" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={collapseMenu}
        >
          <MenuIcon />
        </button>
        <div
          className={`collapse navbar-collapse ${showMenu ? "show" : ""}`}
          id="navbarCollapse"
        >
          <Scrollspy
            items={[
              "home",
              "about",
              "features",
              "screenshot",
              // "blog",
              "client",
              "contact",
            ]}
            offset={-90}
            className="navbar-nav mx-auto navbar-center"
            id="mySidenav"
            currentClassName="active"
          >
            <li className="nav-item">
              <Link
                to="home"
                smooth={true}
                offset={0}
                duration={500}
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={hideMenu}
              >
                Início
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="about"
                smooth={true}
                offset={-50}
                duration={500}
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={hideMenu}
              >
                Quem Somos
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="features"
                smooth={true}
                offset={-50}
                duration={500}
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={hideMenu}
              >
                Soluções
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="screenshot"
                smooth={true}
                offset={-50}
                duration={500}
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={hideMenu}
              >
                Telas
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                to="blog"
                smooth={true}
                offset={-50}
                duration={500}
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={hideMenu}
              >
                Blog
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                to="client"
                smooth={true}
                offset={-50}
                duration={500}
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={hideMenu}
              >
                Clientes
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="contact"
                smooth={true}
                offset={-50}
                duration={500}
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={hideMenu}
              >
                Contato
              </Link>
            </li>
          </Scrollspy>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
