import React from "react";

function ProductsModules({ Icon, title, text }) {
  return (
    <div className="col-lg-4">
      <div className="about_boxes mt-3 text-center rounded p-4">
        <div className="about_icon">
          {Icon !== undefined && Icon !== null && (
            <Icon size={50} className="text-custom" />
          )}
        </div>
        <div className="about_content mt-3">
          <h5 className="font-weight-bold">{title}</h5>
          <p
            className="text-muted mb-0 mt-3"
            style={{ textAlign: "justify", textIndent: 20 }}
          >
            {text}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProductsModules;
