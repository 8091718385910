import React from "react";
import { Helmet } from "react-helmet";

import Menu from "../Menu";
import Home from "../Home";
import About from "../About";
import Products from "../Products";
import Screenshot from "../Screenshot";
// import Blog from "../Blog";
import Clients from "../Clients";
import Footer from "../Footer";

function App() {
  return (
    <>
      <Helmet
        defaultTitle="Play Sistemas Inteligentes"
        titleTemplate={"%s | Play Sistemas Inteligentes"}
      >
        <meta charSet="utf-8" />
      </Helmet>
      <Menu />
      <Home />
      <About />
      <Products />
      <Screenshot />
      {/* <Blog /> */}
      <Clients />
      <Footer />
    </>
  );
}

export default App;
