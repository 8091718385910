import React from "react";
import AboutItem from "./item";

import Trophy from "mdi-react/TrophyIcon";
import Dashboard from "mdi-react/MonitorDashboardIcon";
import StrategyIcon from "mdi-react/StrategyIcon";

function About() {
  return (
    <section className="section mt-5" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center sec_title">
              <p className="sec_small_title text-custom mb-0"></p>
              <h4 className="text-capitalize font-weight-bold mb-0">
                Quem somos
              </h4>
              <div className="main-title-border" />
              <p
                className="mx-auto text-muted mb-0"
                style={{ textAlign: "justify", textIndent: 50 }}
              >
                Mais do que um Software, a <b>PLAY SISTEMAS INTELIGENTES</b>{" "}
                apoia os gestores na busca de melhorias na produtividade, por
                meio de uma solução completa de OTIMIZAÇÃO DO PLANEJAMENTO DE
                PRODUÇÃO, de TRANSPORTES e AUTOMAÇÃO DO OEE com a definição de
                METAS INTELIGENTES para a OPERAÇÃO, com dados analisados por
                INTELIGÊNCIA ARTIFICIAL.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <AboutItem
            Icon={Dashboard}
            title="O que oferecemos"
            text="Otimização do planejamento de produção, de transporte e automação do OEE com definição de metas inteligentes para a operação, baseada em Inteligência Artificial e em conceitos de Lean Manufacturing."
            href="#"
          />
          <AboutItem
            Icon={Trophy}
            title="O que você ganha"
            text="Redução de custos, desperdícios e tempos operacionais, resultando em aumento da produtividade e, por consequência, melhores resultados."
            href="#"
          />
          <AboutItem
            Icon={StrategyIcon}
            title="Nossos Diferenciais"
            text={
              <ul style={{ listStyleType: "none", textAlign: "left" }}>
                <li>Visão Computacional</li>
                <li>Algoritmos Autônomos</li>
                <li>Indústria 4.0</li>
                <li>Internet das Coisas (IoT)</li>
                <li>Robótica</li>
              </ul>
            }
            href="#"
          />
        </div>
      </div>
    </section>
  );
}

export default About;
