import React from "react";
import ProductsModules from "./modules";
import ProductsItem from "./item";

import TruckFast from "mdi-react/TruckFastIcon";
import Warehouse from "mdi-react/WarehouseIcon";
import ChartAreaspline from "mdi-react/ChartAreasplineIcon";

import Automacao from "../../assets/products/automacao-do-oee.png";
import CargaMaquina from "../../assets/products/carga-maquina.png";
import Beneficios from "../../assets/products/beneficios.png";
import Transporte from "../../assets/products/transporte.png";
import Producao from "../../assets/products/producao.png";

function Products() {
  return (
    <section className="section bg-light" id="features">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <p className="sec_small_title text-custom mb-0"></p>
              <h4 className="text-capitalize font-weight-bold mb-0">Produto</h4>
              <div className="main-title-border"></div>
              <p className="mx-auto text-muted mb-0">
                A <b>PLAY SISTEMAS INTELIGENTES</b> oferece soluções em módulos
                que resolvem problemas de forma isolada ou em conjunto
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-12">
            <div>
              <p className="sec_small_title text-custom mb-0"></p>
              <h4 className="text-capitalize font-weight-bold mb-0">Módulos</h4>
            </div>
          </div>
          <ProductsModules
            Icon={TruckFast}
            title="Planejamento do Transporte"
            text="A partir da entrada dos pedidos, otimizamos a MONTAGEM DAS CARGAS e os ROTEIROS DE ENTREGAS com foco em MAXIMIZAR A OCUPAÇÃO DOS CAMINHÕES e em gerar ROTAS ECONÔMICAS."
          />
          <ProductsModules
            Icon={Warehouse}
            title="Planejamento da Produção"
            text="Com base nas cargas montadas, otimizamos o PLANEJAMENTO DA PRODUÇÃO reduzindo setups com a aplicação do CONCEITO DE PRODUÇÃO PUXADA."
          />
          <ProductsModules
            Icon={ChartAreaspline}
            title="Automação do OEE com metas inteligentes para a operação"
            text="Com recursos de IoT, automatizamos a geração do OEE e criamos metas desafiadoras e factíveis, suportadas por dados reais, para as equipes, estimulando a MELHORIA CONTÍNUA."
          />
          <div className="col-lg-12">
            <div className="text-center">
              <p className="mx-auto text-muted mb-0">
                Integração completa entre os módulos, gerando correções de rumo
                sem a necessidade de interação humana.
              </p>
            </div>
          </div>
        </div>

        <ProductsItem
          index={0}
          image={Transporte}
          title="Planejamento do Transporte"
          text={
            <>
              <p>
                Inteligência artificial, algoritmos genéticos e uma heurística
                própria para conjugar pedidos que formem a melhor rota de
                entrega e maximizem a ocupação dos caminhões.
              </p>
              <p>
                A cada 15 minutos, toda a programação é atualizada, considerando
                qualquer modificação ocorrida, como a entrada de novos pedidos
                ou eventuais problemas na operação.
              </p>
              <p>
                Os resultados do módulo PLANEJAMENTO DO TRANSPORTE são medidos
                diretamente na melhoria do indicador OTIF.
              </p>
            </>
          }
        />
        <ProductsItem
          index={1}
          image={Producao}
          title="Planejamento da Produção"
          text={
            <>
              <p>
                A partir das cargas montadas e roteirizadas, o sistema realiza o
                planejamento da produção de modo que os produtos fiquem prontos
                no momento em que serão embarcados.
              </p>
              <p>
                O sistema utiliza ALGORITMOS com elevado nível de autonomia, o
                que exige mínima intervenção humana. Os imprevistos operacionais
                ou pedidos emergenciais são tratados com naturalidade e de forma
                estritamente lógica.
              </p>
              <p>
                Toda programação é atualizada constantemente de maneira
                autônoma.
              </p>
              <p>
                O módulo PLANEJAMENTO DA PRODUÇÃO minimiza tempos de setups,
                reduz custos e desperdícios.
              </p>
            </>
          }
        />
        <ProductsItem
          index={2}
          image={Automacao}
          title="Automação do OEE com metas inteligentes para a operação"
          text={
            <>
              <p>
                Usando Internet das Coisas (IoT), o sistema monitora a operação
                e automatiza a geração do indicador de OEE. Como resultado, os
                gestores recebem uma visão clara e confiável dos principais
                problemas produtivos.
              </p>
              <p>
                A partir dos dados coletados na operação, geramos
                automaticamente Metas desafiadoras e Factíveis para a equipe.
              </p>
              <p>
                O módulo de Automação do OEE garante foco total na melhoria
                contínua.
              </p>
            </>
          }
        />
        <ProductsItem
          index={3}
          image={CargaMaquina}
          title="Carga Máquina"
          text={
            <>
              <p>
                Tenha a visão em tempo real da ocupação de todas as máquinas.
              </p>
              <p>
                Sua equipe comercial encontra oportunidades de vendas em espaços
                ociosos da linha de produção.
              </p>
            </>
          }
        />
        <ProductsItem
          index={4}
          image={Beneficios}
          title="Outros Benefícios"
          features={[
            {
              title: "Visão Computacional",
              text:
                "Algoritmos de Visão Computacional que permitem inspeções visuais feitas por computador. Dessa forma, verificações de qualidade, contagens de peças e outros processos críticos e demorados são feitos com alto nível de precisão e velocidade.",
            },
            {
              title: "Acompanhamento On-line",
              text:
                "Os resultados da operação são acompanhados on-line, via celular, tablet ou computador. Isso significa a viabilidade de uma rápida tomada de ação para correção de eventuais problemas, com mobilidade total.",
            },
            {
              title: "Integração total com seu ERP",
              text: "Sensação de estar trabalhando em um único sistema.",
            },
          ]}
        />
      </div>
    </section>
  );
}

export default Products;
