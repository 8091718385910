import React from "react";

function ClienteItem({ image, href }) {
  return (
    <div className="col-lg-3">
      <div className="logo_img">
        <img src={image} alt="logo-img" className="mx-auto img-fluid d-block" />
      </div>
    </div>
  );
}

export default ClienteItem;
