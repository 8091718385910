import React, { useState } from "react";
import axios from "axios";
import Spinner from "react-spinner-material";

import EmailIcon from "mdi-react/EmailIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import CellphoneIcon from "mdi-react/CellphoneIcon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";

import GoogleMap from "./maps";
import { Contact as C, Address } from "../../config";

function Contact() {
  const initialState = {
    name: "",
    email: "",
    subject: "",
    honeypot: "",
    message: "",
    replyTo: "@",
    accessKey: "96bbb032-9f07-4729-a02a-4d098c0ee50b",
  };
  const [data, setData] = useState({ ...initialState });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [submit, setSubmit] = useState({
    load: false,
    type: "",
    title: "",
    message: "",
  });

  const onChange = (event) => {
    const name = "" + event.target.name;
    const value = "" + event.target.value;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const validateEmail = (value) => {
    let error = "";

    if (!value) {
      error = "E-mail é necessario.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "E-mail informado é invalido.";
    }

    return error;
  };

  const submitForm = (event) => {
    event.preventDefault();

    if (submit.load) return;

    const cMail = validateEmail(data.email);
    const cName = data.name === "" ? "Nome é necessario." : "";
    const cSubject = data.subject === "" ? "Assunto é necessario." : "";
    const cMessage = data.message === "" ? "Uma menssagem é necessario." : "";

    if (cMail !== "" || cName !== "" || cSubject !== "" || cMessage !== "") {
      setErrors({
        name: cName,
        email: cMail,
        subject: cSubject,
        message: cMessage,
      });
      return;
    }

    setSubmit({ load: true, type: "", title: "", message: "" });
    axios({
      method: "POST",
      url: "https://api.staticforms.xyz/submit",
      data: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.data.success) {
          setData({ ...initialState });
          setSubmit({
            load: false,
            type: "success",
            title: "Mensagem enviada.",
            message:
              "Sua mensagem foi enviada com sucesso, estaremos responde sua questão o mais rápido possível.",
          });
        } else {
          setSubmit({
            load: false,
            type: "danger",
            title: "Ops,",
            message:
              "Não foi possivel enviar a sua menssagem, tente novamente em alguns minutos.",
          });
        }
      })
      .catch(() => {
        setSubmit({
          load: false,
          type: "danger",
          title: "Ops,",
          message:
            "Não foi possivel enviar a sua menssagem, tente novamente em alguns minutos.",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setSubmit({
            load: false,
            type: "",
            title: "",
            message: "",
          });
        }, 10000);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="text-white text-center started-text">
            <h1 className="mb-0">Entre em contato</h1>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-7">
          {submit.load === false && submit.type !== "" && (
            <div className={`alert alert-${submit.type}`} role="alert">
              <h4 className="alert-heading">{submit.title}</h4>
              <p>{submit.message}</p>
              <hr />
            </div>
          )}
          <div className="contact_form mx-auto">
            <form onSubmit={submitForm}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group mt-2">
                    <input
                      name="name"
                      id="name"
                      type="text"
                      className="form-control"
                      placeholder="Seu nome..."
                      required=""
                      value={data.name}
                      onChange={onChange}
                    />
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.name}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mt-2">
                    <input
                      name="email"
                      id="email"
                      type="email"
                      className="form-control"
                      placeholder="Seu email..."
                      required=""
                      value={data.email}
                      onChange={onChange}
                    />
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.email}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mt-2">
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      name="subject"
                      placeholder="Assunto desejado.."
                      required=""
                      value={data.subject}
                      onChange={onChange}
                    />
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.subject}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mt-2">
                    <textarea
                      name="message"
                      id="message"
                      rows="4"
                      className="form-control"
                      placeholder="Sua messagem..."
                      required=""
                      value={data.message}
                      onChange={onChange}
                    ></textarea>
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-2 text-center">
                  {submit.load ? (
                    <div
                      className="btn btn-custom"
                      style={{ cursor: "pointer" }}
                    >
                      <Spinner
                        visible
                        size={20}
                        spinnerColor="#FFF"
                        spinnerWidth={2}
                      />
                    </div>
                  ) : (
                    <button className="btn btn-custom w-50">
                      Entrar em contato
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="features_desc mt-3">
            <div className="features_heading">
              <h2 className="text-white">Contato</h2>
              <div className="features_divider mt-3"></div>
              {C.map((c, i) => {
                let IconC = PhoneIcon;
                if (c.type === "tel") {
                  IconC = PhoneIcon;
                } else if (c.type === "cell") {
                  IconC = CellphoneIcon;
                } else if (c.type === "email") {
                  IconC = EmailIcon;
                }

                return (
                  <p
                    className={`text-white mb-0 mt-${i > 0 ? "2" : "4"}`}
                    key={`${c.type}-${i}`}
                  >
                    <IconC
                      size={20}
                      style={{ marginRight: 10, marginTop: -5 }}
                    />
                    {c.text}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="features_desc mt-3" style={{ paddingTop: 20 }}>
            <div className="features_heading">
              <h2 className="text-white">Endereço</h2>
              <div className="features_divider mt-3"></div>
              {Address.map((a, i) => (
                <div key={`mp-${i}`}>
                  <p className="text-white mb-0 mt-4">
                    <MapMarkerIcon
                      size={20}
                      style={{ marginRight: 10, marginTop: -5 }}
                    />
                    {a.text}
                  </p>
                  <GoogleMap url={a.maps} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
