import React from "react";
// import axios from "axios";
import { Link } from "react-scroll";
// import Spinner from "react-spinner-material";

import Twitter from "mdi-react/TwitterIcon";
import Linkedin from "mdi-react/LinkedinIcon";
import Facebook from "mdi-react/FacebookIcon";
import Instagram from "mdi-react/InstagramIcon";

// import Send from "mdi-react/SendIcon";

import Contact from "./contact";
import { Social } from "../../config";

function Footer() {
  // const [newsletter, setNewsletter] = useState("");
  // const [submit, setSubmit] = useState({
  //   load: false,
  //   err: null,
  //   sucess: null,
  // });

  // const onChange = (event) => {
  //   const value = "" + event.target.value;
  //   setNewsletter(value);
  // };

  // const submitForm = (event) => {
  //   event.preventDefault();

  //   if (submit.load) return;
  //   setSubmit({ load: true, err: null, sucess: false });
  //   axios({
  //     method: "POST",
  //     url: "http://localhost:3000/send",
  //     data: { email: newsletter },
  //   })
  //     .then((response) => {
  //       if (response.data.msg === "success") {
  //         setNewsletter("");
  //         setSubmit({ load: false, err: null, sucess: true });
  //       } else if (response.data.msg === "fail") {
  //         setSubmit({
  //           load: false,
  //           err: "Ops, algo deu errado. Tente novamente",
  //           sucess: false,
  //         });
  //       }
  //     })
  //     .catch(() => {
  //       setSubmit({
  //         load: false,
  //         err: "Ops, algo deu errado. Tente novamente",
  //         sucess: false,
  //       });
  //     });
  // };

  return (
    <section className="section bg-contact-color" id="contact">
      <div className="container">
        <Contact />
        <div className="footer_divider mb-5 mt-5"></div>
        <div className="row mt-3">
          <div className="col-lg-6">
            <h6 className="mt-3 text-white text-uppercase">Sobre a Play</h6>
            <div className="mt-3">
              <p className="about_oxcer">
                A <b>PLAY SISTEMAS INTELIGENTES</b> apoia os gestores na busca
                de melhorias na produtividade, que tal acompanhar nossas redes
                sociais.
              </p>
            </div>
            <ul className="list-inline fot_social">
              {Social.twitter !== null && (
                <li className="list-inline-item">
                  <a
                    href={Social.twitter}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="social-icon text-muted"
                  >
                    <Twitter />
                  </a>
                </li>
              )}
              {Social.linkedin !== null && (
                <li className="list-inline-item">
                  <a
                    href={Social.linkedin}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="social-icon text-muted"
                  >
                    <Linkedin />
                  </a>
                </li>
              )}
              {Social.facebook !== null && (
                <li className="list-inline-item">
                  <a
                    href={Social.facebook}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="social-icon text-muted"
                  >
                    <Facebook />
                  </a>
                </li>
              )}
              {Social.instagram !== null && (
                <li className="list-inline-item">
                  <a
                    href={Social.instagram}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="social-icon text-muted"
                  >
                    <Instagram />
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="col-lg-6">
            <h6 className="mt-3 text-white text-uppercase">Páginas</h6>
            <ul className="list-unstyled footer_menu_list mt-3">
              <li>
                <Link
                  to="home"
                  smooth={true}
                  offset={0}
                  duration={500}
                  className="nav-link"
                  style={{ cursor: "pointer", color: "#6c757d" }}
                >
                  Início
                </Link>
              </li>
              <li>
                <Link
                  to="about"
                  smooth={true}
                  offset={-50}
                  duration={500}
                  className="nav-link"
                  style={{ cursor: "pointer", color: "#6c757d" }}
                >
                  Quem Somos
                </Link>
              </li>
              <li>
                <Link
                  to="features"
                  smooth={true}
                  offset={-50}
                  duration={500}
                  className="nav-link"
                  style={{ cursor: "pointer", color: "#6c757d" }}
                >
                  Soluções
                </Link>
              </li>
              <li>
                <Link
                  to="screenshot"
                  smooth={true}
                  offset={-50}
                  duration={500}
                  className="nav-link"
                  style={{ cursor: "pointer", color: "#6c757d" }}
                >
                  Telas
                </Link>
              </li>
              {/* <li>
                <Link
                  to="blog"
                  smooth={true}
                  offset={-50}
                  duration={500}
                  className="nav-link"
                  style={{ cursor: "pointer", color: "#6c757d" }}
                >
                  Blog
                </Link>
              </li> */}
            </ul>
          </div>
          {/*<div className="col-lg-4">
             <h6 className="mt-3 text-white text-uppercase">Newsletter</h6>
            <div className="mt-3">
              <p className="about_oxcer">
                Deseja receber novidades sobre nossos sistemas em seu e-mail?
              </p>
            </div>
            <form onSubmit={submitForm} className="row">
              <div className="col-lg-9">
                <div className="form-group mt-2">
                  <input
                    name="email"
                    id="nl-email"
                    type="email"
                    className="form-control"
                    placeholder="Seu e-mail"
                    required=""
                    value={newsletter}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mt-2">
                  {submit.load ? (
                    <div
                      className="btn btn-custom w-100"
                      style={{
                        cursor: "pointer"
                      }}
                    >
                      <Spinner
                        visible
                        size={15}
                        spinnerColor="#FFF"
                        spinnerWidth={2}
                      />
                    </div>
                  ) : (
                    <button
                      className="btn btn-custom w-100"
                      style={{ padding: 0, margin: 0, height: "38px" }}
                    >
                      <Send size={20} />
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div> */}
        </div>
        <div className="row pt-3">
          <div className="col-lg-12">
            <div className="text-center">
              <p className="copy-rights mb-0">
                {new Date().getFullYear()} &copy; Play Sistemas Inteligentes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
