import React from "react";

function ScreenshortItem({ image, click }) {
  return (
    <div className="col-lg-4">
      <div className="mt-3">
        <button
          style={{ background: "transparent", border: 0 }}
          onClick={() => click()}
        >
          <img
            src={image}
            alt="Screenshot"
            className="img-fluid img-thumbnail mx-auto d-block"
          />
        </button>
      </div>
    </div>
  );
}

export default ScreenshortItem;
