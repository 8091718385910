import React, { useState, useEffect } from "react";

function SliderItem({ title, text, button, image }) {
  const [textView, setTextView] = useState("");
  useEffect(() => {
    if (text === undefined || text === null) {
      setTextView("");
    } else if (Array.isArray(text)) {
      setTextView(
        text.map((t, i) => (
          <p
            key={`#ps-${i}`}
            className="header_subtitle mx-auto mb-0 text-white"
            style={{ fontSize: 18, fontWeight: 700 }}
          >
            {t}
          </p>
        ))
      );
    } else {
      setTextView(text);
    }
  }, [text]);

  return (
    <div className="text-white text-center">
      <h1 className="header_title mx-auto">{title}</h1>
      <div className="pt-4 pb-2">{textView}</div>
      {button && (
        <div className="header_btn">
          <a href={button.url} className="btn btn-custom mt-4 mr-3">
            {button.text}
          </a>
        </div>
      )}
      <div>
        {image && (
          <img
            src={image}
            alt=""
            className="img-fluid mx-auto home-desk d-block"
          />
        )}
      </div>
    </div>
  );
}

export default SliderItem;
