import React from "react";
import ClienteItem from "./item";

import LogoParaibuna from "../../assets/clients/paraibuna.png";
// import LogoCartrom from "../../assets/clients/cartrom.png";

function Clients() {
  const clients = [
    { name: "paraibuna", image: LogoParaibuna, href: "#" },
    // { name: "cartrom", image: LogoCartrom, href: "#" }
  ];

  return (
    <section className="section" id="client">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center sec_title">
              <p className="sec_small_title text-custom mb-0"></p>
              <h4 className="text-capitalize font-weight-bold mb-0">
                Nossos Clientes
              </h4>
              <div className="main-title-border" />
            </div>
          </div>
        </div>
        <div className="row" style={{ alignItems: "center" }}>
          {clients.map((c) => (
            <ClienteItem key={c.name} image={c.image} href={c.href} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Clients;
