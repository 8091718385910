import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import ScreenshortItem from "./item";
import images from "./images";

function Screenshot() {
  const [modal, setModal] = useState({ show: false, index: 0 });

  return (
    <>
      <section className="section" id="screenshot">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="sec_small_title text-custom mb-0"></p>
                <h4 className="text-capitalize font-weight-bold mb-0">Telas</h4>
                <div className="main-title-border"></div>
                <p className="mx-auto text-muted mb-0">
                  Com a PLAY SISTEMA INTELIGENTES, sua equipe fica sabendo
                  exatamente o que fazer e comprometida com a melhoria contínua.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            {images.map((img, i) => (
              <ScreenshortItem
                key={`#SS-${i}`}
                image={img}
                click={() => setModal({ show: true, index: i })}
              />
            ))}
          </div>
        </div>
      </section>

      {modal.show && (
        <Lightbox
          mainSrc={images[modal.index]}
          nextSrc={images[(modal.index + 1) % images.length]}
          prevSrc={images[(modal.index + images.length - 1) % images.length]}
          onCloseRequest={() => setModal(prev => ({ ...prev, show: false }))}
          onMovePrevRequest={() => {
            setModal(prev => ({
              ...prev,
              index: (modal.index + images.length - 1) % images.length
            }));
          }}
          onMoveNextRequest={() => {
            setModal(prev => ({
              ...prev,
              index: (modal.index + images.length + 1) % images.length
            }));
          }}
        />
      )}
    </>
  );
}

export default Screenshot;
