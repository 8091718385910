import React from "react";
import ArrowRight from "mdi-react/ArrowRightIcon";

function AboutItem({ Icon, title, text, href }) {
  return (
    <div className="col-lg-4">
      <div className="about_boxes mt-3 text-center rounded p-4">
        <div className="about_icon">
          {Icon !== null && <Icon size={50} className="text-custom" />}
        </div>
        <div className="about_content mt-3">
          <h5 className="font-weight-bold">{title}</h5>
          {typeof text === "string" ? (
            <p className="text-muted mb-0 mt-3">{text}</p>
          ) : (
            <div className="text-muted mb-0 mt-3">{text}</div>
          )}

          {!!href && href !== "" && href !== "#" && (
            <div className="mt-3 about_btn">
              <a href={href}>
                <ArrowRight />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AboutItem;
